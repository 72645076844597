.App {
  display: flex;
  text-align: center;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-between;
  background-color: #282828;
  background-image: radial-gradient(ellipse farthest-corner at 10% -750%, #282828 0%, #111 80%, #282828 80%);
  color: white;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.App-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.App-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 40%;
}

.App-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 800px;
  min-height: 200px;
  margin-top: 3rem;
}

.App-card {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 2px solid #D4AF37;
  border-radius: 10px;
  min-width: 400px;
  height: 200px;
  transition: color 0.25s ease, border-color 0.25s ease, background-color 0.25s ease;
}

.App-card-title {
  padding-top: 10px;
  color: #D4AF37;
  display: flex;
  align-items: center;
  font-size: 2rem;
  max-width: 80%;
}

.App-card:hover,
.App-card:focus,
.App-card:active {
  color: #FFF;
  border-color: #d2ad3f;
  background-color: rgba(212, 175, 55, 0.05);
}

@media (max-width: 1600px) {
  .App-right {
    display: none;
  }
}

@media (max-width: 1000px) {
  .App-grid {
    flex-direction: column;
    max-width: 350px;
  }
  .App-card {
    min-width: 200px;
    height: auto;
  }
  .App {
    background-color: #111;
    height: 100%;
  }
}
