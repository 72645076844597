.tools-container {
    display: flex;
    text-align: center;
    flex-direction: row;
    min-height: 100vh;
    color: white;
    background-color: #282828;
    background-image: radial-gradient(ellipse farthest-corner at 10% -750%, #282828 0%, #111 80%, #282828 80%);
}

.tools-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    margin-top: 0px;
    flex-wrap: wrap;
    max-width: 90vw;
}

.link-here {
    color: #D4AF37;
}

.link-here:hover {
    color: #e5d79d;
    cursor: pointer;
}

@media (max-width: 1750px) {
    .tools-container {
        flex-direction: column;
        height: 100%;
    }
    .tools-wrapper {
        flex-direction: column;
        height: 100%;
        margin-top: 50px;
    }
}
