.websites-container {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    min-height: 100vh;
    color: white;
    background-color: #282828;
}

.websites-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 24px;
    row-gap: 24px;
    flex-wrap: wrap;
    padding: 24px;
}

.link-here {
    color: #D4AF37;
}

.link-here:hover {
    color: #e5d79d;
    cursor: pointer;
}

@media (max-width: 1450px) {
    .websites-wrapper {
        margin-top: 50px;
    }
}
