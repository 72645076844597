.demo-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 10px;
    padding: 40px 20px;
    margin-top: 20px;
    max-width: 800px;
    overflow: hidden;
    background-color: #343434;
}

.demo-item-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 300px;
}

.demo-item-image {
    height: 350px;
    width: 300px;
    object-fit: cover;
}

.demo-item-text-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 350px;
    width: 500px;
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
}

@media (max-width: 1000px) {
    .demo-item-image-container {
        width: 100px;
        height: 400px;
    }
    .demo-item-image {
        max-height: 100px;
        max-width: 400px;
    }
    .demo-item-text-container {
        height: 400px;
        width: 200px;
        overflow-y: scroll;
        padding-left: 10px;
        padding-right: 10px;
    }
    .demo-item-container {
        padding: 20px 10px;
        max-width: 800px;
        max-height: 400px;
    }
}