.rss-reader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;
}

.card-item {
    background-color: #1d1d1d;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    max-width: 600px;
}

.variation-button {
    display: flex;
    column-gap: 10px;
}

.swiper-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    max-width: 500px;
    background-color: #1d1d1d;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../Images/rss-swiper-background-default.jpg') no-repeat center;
    background-size: cover;
    align-items: center;
    justify-content: space-between;
}

.top-menu {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-around;
}

.variation-button-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
}

.news-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    text-align: center;
    padding: 0 30px;
}

.common-typography {
    border-radius: 10px;
    padding: 10px;
    user-select: none;
}

.subtitle-text {
    color: rgb(210, 210, 210);
    font-weight: 400;
    background-color: #1d1d1d34;
    padding: 2px 5px;
    border-radius: 4px;
    user-select: none;
}

.bottom-menu {
    display: flex;
    padding-bottom: 10px;
    width: 100%;
    justify-content: space-around;
}