.poker-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    color: white;
    background-color: #282828;
}

.poker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.start-session-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    min-height: 200px;
    padding-top: 16px;
}

.horizontal-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.poker-image {
    max-width: 80vw;
    max-height: 30vh;
}
