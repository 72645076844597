.focus-image {
    max-width: 70vw;
    max-height: 30vh;
}

.continue-button-container {
    display: flex;
    margin-top: 1rem;
}

.focused-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #282828;
}

.guided-tour-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
