.Software-App {
    display: flex;
    text-align: center;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: space-between;
    background-color: #282828;
    background-image: radial-gradient(ellipse farthest-corner at 10% -750%, #282828 0%, #111 80%, #282828 80%);
    color: white;
}

.Software-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.Software-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 40%;
}

.Software-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.Software-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 80%;
    min-height: 200px;
    margin-top: 3rem;
}

.Software-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 32px;
}

.Software-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 700px;
}

.Software-grid a {
    text-decoration: none;
    color: #D4AF37;
}

.back-to-top-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 80px;
}

.link-here {
    color: #D4AF37;
}

.link-here-scroll {
    color: #D4AF37;
    font-size: 4rem;
    padding-top: 60px;
}

.link-here:hover,
.link-here-scroll:hover {
    color: #e5d79d;
    cursor: pointer;
}

.Software-grid a :hover {
    text-decoration: none;
    color: #e5d79d;
}

.paddingTop {
    padding-top: 24px;
}

.About-me-container {
    background-color: #111111;
    width: 100%;
    color: white;
}

.Experience-container {
    background-color: #282828;
    width: 100%;
    color: white;
}

.profileWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.experienceWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 30px;
}

.profileImage {
    display: flex;
    padding-left: 24px;
    max-width: 50vw;
    max-height: 100vh;
}

.profileDetails {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experienceDetails {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 80px;
    height: 100%;
}

.experience-logo {
    max-height: 60px;
    max-width: 120px;
    min-width: 10px;
}

td {
    width: fit-content;
    padding-left: 80px;
}

@media (max-width: 1600px) {
    .Software-right {
        display: none;
    }
}

@media (max-width: 1000px) {
    .Software-grid {
        flex-direction: column;
        max-width: 50%;
        min-height: 400px;
        margin-top: 1rem;
    }
    .Software-App {
        background-color: #111;
    }
    .Software-section-wrapper {
        min-width: 350px;
    }
    .profileImage {
        padding-left: 12px;
        max-width: 30vw;
    }
    .link-here-scroll {
        font-size: 2rem;
        padding-top: 20px;
    }
    td {
        padding-left: 10px;
    }
    .experience-logo {
        display: none;
    }
    .experienceWrapper {
        height: 100%;
    }
    .back-to-top-wrapper {
        margin-bottom: 20px;
    }
}