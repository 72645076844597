.poker-session-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    color: white;
    background-color: #282828;
}

.poker-session-header-container {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    padding-right: 24px;
}

.poker-session-voting-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    justify-self: center;
    width: 100vw;
    height: 100vh;
    padding-top: 24px;
}

.poker-session-voting-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    justify-self: center;
    width: 100vw;
    padding-top: 48px;
    height: fit-content;
}

.poker-session-admin-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
}

.poker-session-voting-buttons-wrapper {
    width: 400px;
    max-width: 80vw;
}

.poker-session-players-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: flex-start;
}

td {
    min-width: 100px;
    text-align: left;
}